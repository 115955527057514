<template>
    <div class="row m-0 px-0">
        <div class="col-12 p-0 m-0">
            <div class="bg-black pt-lg--10 pt-7">
                <img :src="'/images/bg-about/bg-banner.jpg'" class="w-100" alt="">
            </div>
            <SectionOne></SectionOne>
            <SectionTwo></SectionTwo>
            <SectionThree></SectionThree>
            <SectionFour></SectionFour>
        </div>
    </div>
</template>
<script>
import SectionOne from './Section/SectionOne.vue'
import SectionTwo from './Section/SectionTwo.vue'
import SectionThree from './Section/SectionThree.vue'
import SectionFour from './Section/SectionFour.vue'
export default {
    components: {
        SectionOne,
        SectionTwo,
        SectionThree,
        SectionFour,
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    .bg-black .pt-5 {
        padding: 0 !important;
    }
}
</style>