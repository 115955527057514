<template>
    <div class="section-two w-100 pt-10 pb-5">
        <img :src="'/images/bg-about/half-circle-right.svg'" width="100px" alt="" class="circle-right">
        <img :src="'/images/bg-about/half-circle-left.svg'" width="100px" alt="" class="circle-left">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row justify-content-center mb-5">
                    <div class="col-12 text-center">
                        <h1 class="fw-700 text-black header-text">Why Elingway?</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row justify-content-between mb-lg-5 mb-4">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-12">
                                        <video class="rounded-lg" width="100%" controls controlsList="nodownload" :poster=" data.main.image" v-if="data.main && data.main.video">
                                            <source :src=" data.main.video" type="video/mp4">
                                            <source :src=" data.main.video" type="video/ogg">
                                        </video>
                                    </div>
                                    <div class="col-12">
                                        <span class="fw-400 text-black font-sm" v-html="data.main.text"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <table class="mb-3">
                                    <tr>
                                        <td><img :src="'/images/bg-about/sec2-checklist.svg'" width="30px" alt=""></td>
                                        <td class="pl-2 py-3"><span class="fw-500 text-black font-sm">BERBEDA</span></td>
                                    </tr>
                                    <tr>
                                        <td><img :src="'/images/bg-about/sec2-checklist.svg'" width="30px" alt=""></td>
                                        <td class="pl-2 py-3"><span class="fw-500 text-black font-sm">MENDUKUNG</span></td>
                                    </tr>
                                    <tr>
                                        <td><img :src="'/images/bg-about/sec2-checklist.svg'" width="30px" alt=""></td>
                                        <td class="pl-2 py-3"><span class="fw-500 text-black font-sm">MEMBERI LEBIH</span></td>
                                    </tr>
                                </table>
                                <router-link to="/get-started" class="know-button">Know More</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            data: {
                main: {
                    id: '',
                    image: '',
                    text: '',
                    title: '',
                    video: ''
                }
            }
        }
    },
    created() {
        this.getContent()
    },
    methods: {
        async getContent(){
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/about-us/content`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.data.main = res.data.data.main
                }
            }).finally(() => this.isLoad = false)
        },
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    .header-text {
        font-size: 35px !important;
    }
}
.header-text {
    font-size: 50px;
}
.know-button {
    background-color: #FE9431;
    padding: 15px 30px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}
.circle-right {
    position: absolute;
    top: 1400px;
    left: 0;
}
.circle-left {
    position: absolute;
    top: 2000px;
    right: 0;
}
</style>