<template>
    <div class="section-four w-100 pb-5">
        <div class="row justify-content-center min-vh-125">
            <div class="col-10">
                <div class="row justify-content-center mt-4 mb-5">
                    <div class="col-12 text-center">
                        <h1 class="fw-700 text-black header-text">Frequently Asked Questions</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div v-if="!faqLoad" class="row justify-content-end">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Search FAQ" v-model="search" @change="searchFaq()">
                                </div>
                            </div>
                            <div class="col-12" v-if="faq.data.length">
                                <div id="accordion" class="accordion p-0">
                                <div class="card border-0 mb-4" v-for="(item, index) in faq.data" :key="index">
                                    <div class="card-header bg-current pt-2 px-3 cursor-pointer" :id="'heading'+index" @click="openBar(index)">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed text-white mb-0" :id="`btn-acc-${index}`" data-toggle="collapse" :data-target="'#collapse'+index" aria-expanded="false" :aria-controls="'collapse'+index" v-html="item.question">
                                            </button>
                                        </h5>
                                    </div>
                                    <div :id="'collapse'+index" class="collapse" :aria-labelledby="'heading'+index" data-parent="#accordion">
                                        <div class="card-body bg-lightgrey">
                                            <p v-html="item.answer"></p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mb-4" v-if="faq.data.length">
                                <ul class="pagination pagination-center justify-content-center d-flex pt-5">
                                    <li v-for="(link, index) in faq.links" :key="index" class="page-item m-1">
                                        <button v-if="link.label === '&laquo; Previous'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="prevCourse()"><i class="ti-angle-left"></i></button>
                                        <button v-else-if="link.label === 'Next &raquo;'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="nextCourse()"><i class="ti-angle-right"></i></button>
                                        <button v-else v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" @click="changePage(link.label)">{{link.label}}</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 text-center" v-if="faq.data.length == 0">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col-lg-12">
                                <div class="my-3 text-center">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            faqLoad: true,
            faq: {
                data: []
            },
            search: '',
            page: 1,
        }
    },
    created(){
        this.getFAQ()
    },
    methods: {
        openBar(index) {
            let btn = document.getElementById('btn-acc-'+index)
            if(btn) {
                btn.click()
            }
        },
        async getFAQ(){
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/about-us/faq?page=${parseInt(this.page)}&search=${this.search}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.faq = res.data.data
                    this.faqLoad = false
                }
            }).catch((err) => console.error(err))
        },
        searchFaq(){
            this.page = 1
            this.getFAQ()
        },
        changePage(page) {
            this.page = parseInt(page)
            this.getFAQ()
        },
        prevCourse() {
            if (this.page >= 1) {
                this.page -= 1
                this.getFAQ()
            }
        },
        nextCourse() {
            if (this.faq.last_page > this.page) {
                this.page += 1
                this.getFAQ()
            }
        },
    }
};
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    .header-text {
        font-size: 35px !important;
    }
}
.header-text {
    font-size: 50px;
}
::v-deep .btn-link p {
    margin: 0;
    color: #fff !important;
}
.accordion .card button:after {
    color: #ffffff !important;
}
</style>