<template>
    <div class="section-three w-100 pt-10">
        <div class="row justify-content-center bg-yellows">
            <div class="col-12">
                <div class="row justify-content-center mt-4">
                    <div class="col-10 text-center">
                        <h1 class="fw-700 text-black header-text">Our Program</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 bg-road">
                <div class="row justify-content-center min-vh-100 pt-lg-4 pt-2">
                    <div class="col-10">
                        <table class="program-table">
                            <tr v-for="(item, index) in data" :key="index">
                                <td class="px-2 text-center" valign="top"><a href="#" class="program-number">{{ index+1 }}</a></td>
                                <td width="100%" valign="top" class="pb-5">
                                    <div class="row">
                                        <div class="col-lg-1 col-md-2">
                                            <div class="row pl-lg-2">
                                                <div class="col-12 text-left mb-2">
                                                    <span class="fw-700 text-black font-xss mb-3">{{ item.title }}</span>
                                                </div>
                                                <div class="col-12 text-left">
                                                    <img class="text-left" :src=" item.image" height="40px" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mt-lg-0 mt-3 pt-lg-3">
                                            <div class="pt-lg-3">
                                                <span class="fw-500 text-black font-sm pl-lg-5">{{ item.text }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <!-- <td class="px-2 pt-2" valign="center"></td> -->
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            data: []
        }
    },
    created() {
        this.getMilestone()
    },
    methods: {
        async getMilestone(){
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/about-us/program`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data
                this.isLoad = false
            })
        },
    }
}
</script>
<style scoped>
@media only screen and (max-width: 1023px) {
    .bg-road {
        background-size: auto 60% !important;
    }
}
@media only screen and (max-width: 991px) {
    .bg-road {
        background-position: -2% 3% !important;
        background-size: auto 80% !important;
    }
}
/* @media only screen and (max-width: 767px) {
    .bg-road {
        background-position: 10% 2% !important;
        background-size: auto 45% !important;
    }
} */
@media only screen and (max-width: 689px) {
    .bg-road {
        background: none !important;
    }
    /* .bg-yellows {
        background: none !important;
    } */
}
@media only screen and (max-width: 992px) {
    .header-text {
        font-size: 35px !important;
    }
}
.header-text {
    font-size: 50px;
}
.bg-road {
    background-image: url('/images/bg-about/sec3-road-strip.svg');
    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: 9% 6%;
}
.bg-yellows {
    background-image: url('/images/bg-about/blurred-yellow.png');
    background-size: 89%;
    background-repeat: no-repeat;
    background-position: calc(100% * -5.5) 0;
}
.program-number {
    padding: 10px 15px;
    background-color: #eee;
    color: #000;
    font-weight: 800;
    font-size: 18px;
    font-style: italic;
    text-align: center;
}
.road {
    position: absolute;
    left: 5.4%;
    top: 56%;
}
.blured-yellow {
    position: absolute;
    left: -65%;
    top: 54%;
    z-index: -2;
}
</style>