<template>
    <div class="section-one w-100 pt-10 pb-5">
        <img :src="'/images/bg-about/half-circle-right.svg'" width="100px" alt="" class="circle-right">
        <img :src="'/images/bg-about/half-circle-left.svg'" width="100px" alt="" class="circle-left">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row justify-content-center mt-4 mb-lg-5 mb-3">
                    <div class="col-lg-9">
                        <h1 class="fw-700 text-black header-text">Milestone</h1>
                    </div>
                </div>
                <div class="row min-vh-50">
                    <div class="col-12" v-if="!isLoad">
                        <div class="row">
                            <div class="col-12" v-for="(item, index) in data" :key="index">
                                <div class="row">
                                    <div class="col-lg-2 col-md-4 bright py-md-4 pl-md-4">
                                        <h2 class="fw-500 text-current display1-size"><em>{{ item.year }}</em></h2>
                                    </div>
                                    <div class="col-lg-10 col-md-8 py-md-4 pl-md-4 pb-sm-4 pb-xs-4 pb-lg-0 pb-md-0 pb-3">
                                        <h2 class="fw-500 text-black font-md mt-1">{{ item.description }}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <table class="milestone-table">
                            <tr v-for="(item, index) in data" :key="index">
                                <th class="pr-lg-5 pr-3 pl-lg-3 pl-3"><h2 class="fw-500 text-current display1-size"><em>{{ item.year }}</em></h2></th>
                                <td class="pl-lg-3 pl-4 py-5"><h2 class="fw-500 text-black font-md">{{ item.description }}</h2></td>
                            </tr>
                        </table> -->
                    </div>
                    <div class="col-12 text-center" v-else>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            isLoad: true,
            data: []
        }
    },
    created() {
        this.getMilestone()
    },
    methods: {
        async getMilestone(){
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/milestones`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data
                this.isLoad = false
            })
        },
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    .header-text {
        font-size: 35px !important;
        text-align: center !important;
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 767px) {
    .bright {
        border-right: none !important;
    }
}
.header-text {
    font-size: 50px;
}
.bright {
    border-right: 1px solid #000;
}
.circle-right {
    position: absolute;
    top: 700px;
    left: 0;
}
.circle-left {
    position: absolute;
    top: 1100px;
    right: 0;
}
</style>